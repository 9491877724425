import '../../styles/Body.css'

import PeopleSideNavbar from '../../components/PeopleSideNavbar';

function PastWinners() {
  return (
    <div className = "body-root">
      <div className='body-content'>
        
        <div className="banner">
          <img src={require("../../images/golead-people.png")} alt="goLEAD" height="auto" width="100%"></img>
        </div>
        
        <div className='side-panel'>
          <PeopleSideNavbar />
        </div>

        <div className="content-with-side-panel">
          <div>
            <h1>Past Winners</h1>
          </div>
          <div className="break"></div>

          <div>
            <h2>Activate Winter 2022</h2>
          </div>
          <div className="break"></div>

          <div className="winners-two-panel">
            <h3>Overall Winners</h3>
            <hr/>
            <p>Nadia Bogayevsky, McGill University</p>
            <p>Daniiar Omurbekov, University of Manitoba</p>
            <p>Malanie Tham, University of Toronto</p>
            <p>Po-Hsueh Chiu, Concordia University</p>
          </div>
          <div className="winners-two-panel">
            <h3>Best Learning Outcomes</h3>
            <hr/>
            <p>Areeb Khawaja, McMaster University</p>
            <p>Shivalika Sirohi, McMaster University</p>
            <p>Mohit Anil Lal, McMaster University</p>
          </div>
          <div className="break"></div>
          
          <div>
            <h2>Move Fall 2021</h2>
          </div>
          <div className="break"></div>

          <div className="winners-one-panel">
            <h3>Overall Winners</h3>
            <hr/>
            <p>Atharva Shirsat, McGill University</p>
            <p>Zoe Shing, McGill University</p>
            <p>Elif Yapici Tanyeri, McGill University</p>
          </div>
          <div className="break"></div>
          
          <div>
            <h2>Activate Winter 2021</h2>
          </div>
          <div className="break"></div>

          <div className="winners-two-panel">
            <h3>Overall Winners</h3>
            <hr/>
            <p>Adam Chatelan, McGill University</p>
            <p>Simo Benkirane, McGill University</p>
            <p>Ana&euml;lle Drai Lagu&eacute;ns, McGill University</p>
            <p>Aya el Khazen, McGill University</p>
            <p>Sherif Azmy, McGill University</p>
            <p>Olivier Forster, McGill University</p>
            <p>Aymen Boustani, McGill University</p>
          </div>
          <div className="winners-two-panel">
            <h3>Best Learning Outcomes</h3>
            <hr/>
            <p>Neeraj Katiyar, McGill University</p>
            <p>Yawan Xu, McGill University</p>
            <p>Dani&egrave;le Sossou, McGill University</p>
          </div>
          <div className="break"></div>

          <div>
            <h2>Move Fall 2020</h2>
          </div>
          <div className="break"></div>

          <div className="winners-one-panel">
            <h3>Overall Winners</h3>
            <hr/>
            <p>Lulan Shen, McGill University</p>
            <p>Ruofeng Li, McGill University</p>
            <p>Yirui Ren, McGill University</p>
            <p>Guneet Kour, McGill University</p>
          </div>
          <div className="break"></div>

          <div>
            <h2>Activate Winter 2020</h2>
          </div>
          <div className="break"></div>

          <div className="winners-two-panel">
            <h3>Overall Winners</h3>
            <hr/>
            <img className="people-img" src={require("../../images/winners/activate-winter-2020-overall-winners.jpg")} alt="activate-winter-2020-overall-winners"></img>
            <hr/>
            <p>Lea Kassab, McGill University</p>
            <p>Haihen Chen, McGill University</p>
            <p>Alex Gruenwald, McGill University</p>
            <p>Kamy Moussavi, McGill University</p>
            <p>Vasily Piccone, McGill University</p>
          </div>
          <div className="winners-two-panel">
            <h3>Best Learning Outcomes</h3>
            <hr/>
            <img className="people-img" src={require("../../images/winners/activate-winter-2020-best-learning-outcomes.jpg")} alt="activate-winter-2020-best-learning-outcomes"></img>
            <hr/>
            <p>Mia Zhou, McGill University</p>
            <p>Vanja Lugonjic, McGill University</p>
            <p>Samantha Gangapersad, McGill University</p>
            <p>Nogieru Eghobamien, McGill University</p>
          </div>
          <div className="break"></div>

          <div>
            <h2>Move Fall 2019</h2>
          </div>
          <div className="break"></div>

          <div className="winners-one-panel">
            <h3>Overall Winners</h3>
            <hr/>
            <img className="people-img" src={require("../../images/winners/move-fall-2019-overall-winners.jpg")} alt="move-fall-2019-overall-winners"></img>
            <hr/>
            <p>Mahir Ar-Rahman, McGill University</p>
            <p>Ryan Irwin, McGill University</p>
            <p>Yangyang Jin, McGill University</p>
            <p>Mohammad Hossein Motavas, McGill University</p>
          </div>
          <div className="break"></div>

          <div>
            <h2>Activate Winter 2019</h2>
          </div>
          <div className="break"></div>

          <div className="winners-two-panel">
            <h3>Overall Winners</h3>
            <hr/>
            <p>Kirklann Lau, McGill University</p>
            <p>Simina Alungulesa, McGill University</p>
            <p>Cherng-Haw Luoh, McGill University</p>
            <p>Aissam Souidi, McGill University</p>
            <p>Misghana Kasaa, McGill University</p>
          </div>
          <div className="winners-two-panel">
            <h3>Best Learning Outcomes</h3>
            <hr/>
            <p>Quang Ahn Nguyen, McGill University</p>
            <p>Eleonore Robbins, McGill University</p>
            <p>Pinghe Zhu, McGill University</p>
            <p>Laura Pena Silva, McGill University</p>
            <p>Timothee Dulac, McGill University</p>
            <p>Rim El Osta, McGill University</p>
          </div>
          <div className="break"></div>

          <div>
            <h2>Move Fall 2018</h2>
          </div>
          <div className="break"></div>

          <div className="winners-one-panel">
            <h3>Overall Winners</h3>
            <hr/>
            <img className="people-img" src={require("../../images/winners/move-fall-2018-overall-winners.jpg")} alt="move-fall-2018-overall-winners"></img>
            <hr/>
            <p>Oliver Durnan, McGill University</p>
            <p>Diana Nigmatu, McGill University</p>
            <p>Matthias Arabian, McGill University</p>
            <p>Monami Waki, McGill University</p>
            <p>Emily Chen, McGill University</p>
          </div>
          <div className="break"></div>

          <div>
            <h2>Move Winter 2018</h2>
          </div>
          <div className="break"></div>

          <div className="winners-one-panel">
            <h3>Overall Winners</h3>
            <hr/>
            <p>Bin Sur Yip, McGill University</p>
            <p>Emmanuel Edeh, McGill University</p>
            <p>Jonathan Sharir-Smith, McGill University</p>
            <p>Yun Zhong, McGill Univesity</p>
            <p>Enan Ashaduzzaman, McGill University</p>
          </div>
          <div className="break"></div>


        </div>
      </div>
    </div>
  );
}

export default PastWinners;
