import '../styles/Body.css'

export default function RegistrationPanel(){
    return(
        <div className = "body-root">
                <div>
                    <h2>Do you want to improve the world, but don't know where to start yet?</h2>
                    <p>We work collaboratively to support and empower people to set and achieve their goals to better the world.</p>
                </div>
                <div className="break"></div>
    
                <div className="center">
                    <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=cZYxzedSaEqvqfz4-J8J6ot35iQt7NRNvhM_tlElNN5UOTJHSjdXM1A2QzNXTENXRU8xN0pPS0IyUCQlQCN0PWcu" className="button">Move Fall 2022 Registration is now open!</a>
                </div>
                <div className="break"></div>
        </div>
    );
}
