import '../styles/Body.css'

function Testimonials() {
  return (
    <div className = "body-root">
      <div className='body-content'>
        <div>
          <h1>Binsur Yip, Pharmacology and Food Science</h1>
        </div>
        <div className="break"></div>
        <div>
          <p><b>“The most memorable moments for me were all the team-building exercises (the human knot, building a parachute and the highest tower). They were both fun and were effective at teaching us how to approach a problem and how to work as a team.</b></p>
          <p>The MOVE program positively impacted my student experience. It is rather counter intuitive because of the additional workload but this program diminished my procrastination tendencies. The deadlines of this project pushed me to better organize my time for studying and assignments.</p>
          <p>This program also taught me many things: how to effectively work as a team, how to find and communicate with stakeholders and how to present a solution to a problem. Other than the project itself, it taught me how to better manage my time, how to be a good team player (motivation throughout the project was always a problem) and how to communicate effectively. This program allows us to use our creativity in finding and presenting our solution.</p>
          <p>Overall, I would most definitely recommend the MOVE program to my friends. This program targets the development of important transferable skills that are incredibly useful in any field such as communication, teamwork, problem solving, oral presentation, self-motivation, responsibility, decisiveness, time management and conflict resolution. Practicing these skills are crucial in becoming a good leader.”</p>
        </div>
        <div className="break"></div>

        <div>
          <h1>Th&eacute;o Lescoat, Materials Engineering</h1>
        </div>
        <div className="break"></div>
        <div>
          <p><b>"The best element about the MOVE program is bringing people together to think about how to solve a problem.</b></p>
          <p>The most memorable moment I had in the program was when my team came together to discuss our interests and find common ones. It turns out that there were very many! Our discussions ranged over many subjects, from societal problems, to economic ones, and it was refreshing to see that other people saw similar problems in the world. That being said, there were things that I had never thought of before that I was first exposed to during MOVE. That’s what was important and memorable: getting exposure.</p>
          <p>It was important to me to go to MOVE. It felt like a serious task, requiring work and focus, but it was outside the traditional academic context. I especially appreciated spending time with Professor Quitoriano. As students we do not necessarily get that sort of privileged exposure. Furthermore, I got to meet students whom I would have never met otherwise, mostly because we were not in the same program.</p>
          <p>I learned from the MOVE program that there are many problems out there, yet many people who want to address them as well as multiple ways to do so. I also learned that teamwork is key to advancing a project: we may perform well as individuals, but summing up our efforts brings something more. I also learned how to decompose a large problem into a set of smaller ones. I realized thanks to MOVE that this is crucial. Indeed, it is much simpler to solve a set of small problems rather than one large problem. I look forward to applying this in future projects.</p>
          <p>I would recommend the MOVE program to friends for a few reasons. First, I got to work on a problem that interest me, with tools that were taught during the workshops. Second, I met many people and got exposure to all kinds of different mindsets, opinions and interests. Third, I got to have more personal, and therefore enriching, conversations with Professor Quitoriano, about subjects outside of class material. I hope to have the opportunity to continue in that vein."</p>
        </div>
        <div className="break"></div>

        <div>
          <h1>Fady Ramlawi, Materials Engineering</h1>
        </div>
        <div className="break"></div>
        <div>
          <p><b>"The MOVE program helped me get a better idea to what being a social entrepreneur would be like. The earlier sessions encouraged deep thought on the world's problems, which presented the opportunity for engaging analyses of global issues and breaking them down to their more fundamental principles/causes. It was a good opportunity to practice problem solving in a more realistic setting, which forced me to more deeply acknowledge the constraints and factors that hinder progress.</b></p>
          <p>One thing I learnt during the program was that it is easy to fall into the trap of finding or even creating a problem to be solved by an existing solution. The most effective solution is generally one that is tailored to a specific problem. So I'd say getting to know the problem you're tackling is absolutely vital in implementing a solution.</p>
          <p>I'd recommend the program to anyone who is interested in tacking social problems, pursuing a career in entrepreneurship or operating at an executive level of an organization. Participation in MOVE will help develop your teamwork and communication skills.</p>
        </div>
        <div className="break"></div>

      </div>
    </div>
  );
}

export default Testimonials;
